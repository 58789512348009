import React, { useState } from "react";
import { useEffect } from "react";
// import useFetch from "../../hooks/useFetch";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import DATA from "./members";
import "./HexGrid.css";

// const API_BASE = `${process.env.REACT_URL}/api/public/member/all`;
// const API_BASE =
// 	"http://ec2-13-234-119-163.ap-south-1.compute.amazonaws.com:8000/api/public/member/all";

const HexGrid = ({ yearindx }) => {
	// const { data, error, loading } = useFetch(API_BASE);
	// if (!loading) console.log(data);
	console.log(process.env.REACT_URL);
	const [loading, setLoading] = useState(false);
	const [members, setMembers] = useState(DATA);
	const [yearOfStudy, setYearOfStudy] = useState("");
	useEffect(() => {
		if (yearindx === 0) {
			setYearOfStudy("final");
		} else if (yearindx === 1) {
			setYearOfStudy("third");
		} else if (yearindx === 2) {
			setYearOfStudy("alumni_2024");
		} else if (yearindx === 3) {
			setYearOfStudy("alumni_2023");
		}
	}, [yearindx]);

	const fetchData = async () => {
		setLoading(true);
		// const res = await fetch(API_BASE);
		const data = DATA;
		setMembers(data);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="main mx-[1rem] w-full mt-[5rem]">
			<div className="hexGrid">
				{loading
					? "Loading"
					: members.map((member) => {
							if (member.category === yearOfStudy) {
								return (
									<div key={member._id} className="before:bg-red-900">
										<span className={`hex `}>
											<div
												className={`landing-img absolute top-0 w-full  -mt-3 back_img`}
											>
												<img
													src={`${member.image}`}
													alt="hello"
												/>
											</div>
											<div className="absolute w-full h-full -rotate-90 top-0 flex items-center justify-around flex-col text-white opacity-0 z-10 displayHover">
												<h1 className="teamMember--name basis-1/2 align-center mt-8 w-3/4 text-center">{`${member.first_name} ${member.last_name}`}</h1>
												
												{member.office_post && (
                                                                                                <p className="teamMember--post bg-gray-800 alpha-0.5 rounded-xl p-2">
                                                                                                 {member.office_post}
                                                                                                 </p> )}
                      
												<div className="flex basis-1/2 mb-8 items-center justify-center gap-3 w-full">
													<a
														href={`https://www.instagram.com/${member.socials.instagram}`}
														target="_blank"
														rel="noopener noreferrer"
														className="socialHover"
													>
														{" "}
														<BsInstagram size={20} />{" "}
													</a>
													<a
														href={`https://www.linkedin.com/${member.socials.linkedin}`}
														target="_blank"
														rel="noopener noreferrer"
														className="socialHover"
													>
														{" "}
														<BsLinkedin size={20} />{" "}
													</a>
													<a
														href={`https://twitter.com/${member.socials.twitter}`}
														target="_blank"
														rel="noopener noreferrer"
														className="socialHover"
													>
														{" "}
														<BsTwitter size={20} />{" "}
													</a>
												</div>
											</div>
										</span>
									</div>
								);
							}
							return null;
					  })}
			</div>
			<svg
				style={{ visibility: "hidden", position: "absolute" }}
				width="0"
				height="0"
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
			>
				<defs>
					<filter id="round">
						<feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
						<feColorMatrix
							in="blur"
							mode="matrix"
							values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
							result="goo"
						/>
						<feComposite in="SourceGraphic" in2="goo" operator="atop" />
					</filter>
				</defs>
			</svg>
		</div>
	);
};

export default HexGrid;
