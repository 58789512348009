const DATA = [

  /////////////////////------->2024 batch <------------------/////////////////////////////
  {
    socials: {
      instagram: "adityaghosh9190",
      linkedin: "in/aditya-ghosh-719216219/",
      twitter: "aditya__ghosh__",
    },
    _id: "642d72dd77140e24741ffa4c",
    first_name: "aditya",
    last_name: "ghosh",
    email: "adityag30802@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/adityaghosh.webp",
    // office_post: "Web-Dev Head"
  },
  {
    socials: {
      instagram: "anurrag.s",
      linkedin: "in/anurag-sharma-a47b611b1/",
      twitter: "anurrag_s",
    },
    _id: "642d72dc77140e24741ffa44",
    first_name: "anurag",
    last_name: "sharma",
    email: "iamanurag0501@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/anuragsharma.webp",
    // office_post:"Vice President",
  },
  {
    socials: {
      instagram: "archishman_das",
      linkedin: "in/archishman-das-4584b21a0/",
      twitter: "das_archishman",
    },
    _id: "642d72dd77140e24741ffa4a",
    first_name: "archishman",
    last_name: "das",
    email: "sumita123prl@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/archishmandas.webp",
    // office_post: "Treasurer",
  },
  
  {
    socials: {
      instagram: "satyagowtham12",
      linkedin: "in/gowtham-satya-213257231",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa4e",
    first_name: "gowtham",
    last_name: "satya",
    email: "gowthamsatya5@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/gowthamsatya.webp",
    // office_post: "Operations Head"
  },

  {
    socials: {
      instagram: "hima_khaitan",
      linkedin: "in/himakhaitan",
      twitter: "hima_khaitan",
    },
    _id: "642c75bae4928d57dfc22b2f",
    first_name: "himanshu",
    last_name: "khaitan",
    email: "himanshukhaitan10@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/himanshukhaitan.webp",
    // office_post: "President",

  },
    

  {
    socials: {
      instagram: "nilashism",
      linkedin: "in/nilashis-mukherjee-5b2247210",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa50",
    first_name: "nilashis",
    last_name: "mukherjee",
    email: "nilashism@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/nilashismukherjee.webp",
    // office_post: "Research Head"
  },

  {
    socials: {
      instagram: "aryan.raj8080",
      linkedin: "in/rajukumar6",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa48",
    first_name: "raju",
    last_name: "kumar",
    email: "rajukumar1252002@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/11/rajuda.jpeg",
    // office_post: "AGS and Design Head"
  },

  {
    socials: {
      instagram: "____.satyam.__",
      linkedin: "in/satyam-rajput-b8420b211",
      twitter: "",
    },
    _id: "643998e4b6c4febf1c5f3482",
    first_name: "satyam",
    last_name: "rajput",
    email: "satyamrajput881@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/satyamrajput.webp",
    // office_post: "Publicity Head"
  },


  {
    socials: {
      instagram: "suvro_23",
      linkedin: "in/shakti-karmakar-5676401aa/",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa54",
    first_name: "shakti shankar",
    last_name: "karmakar",
    email: "shaktikarmakar23@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/shaktishankarkarmakar.webp",
    // office_post: "Convenor",
  },

  
  {
    socials: {
      instagram: "_the_mundhra_",
      linkedin: "in/shubham-mundhra-62b32b242/",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa52",
    first_name: "shubham",
    last_name: "mundhra",
    email: "smundhra2000@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/shubhammundhra.webp",
    // office_post: "App-Dev Head"
  },
  {
    socials: {
      instagram: "sindhu_singh__",
      linkedin: "in/sindhu-singh-b44720218",
      twitter: "sindhu__007",
    },
    _id: "64398d45b6c4febf1c5f3474",
    first_name: "sindhu",
    last_name: "singh",
    email: "singhsindhu7680@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/sindhusingh.webp",
    // office_post: "General Secretary",
  },

  {
    socials: {
      instagram: "suddhasilc",
      linkedin: "in/suddhasilchatterjee",
      twitter: "suddhasilc",
    },
    _id: "642d72dc77140e24741ffa46",
    first_name: "suddhasil",
    last_name: "chatterjee",
    email: "csuddhasil@gmail.com",
    category: "alumni_2024",
    post: null,
    batch: "2024",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/suddhasilchatterjee.webp",
    // office_post: "Event Head"
  },
 
  ////////////////////////////---------->2023 batch<-----------------/////////////////////////////

  {
    socials: {
      instagram: "iabhishekbasu",
      linkedin: "in/iabhishekbasu/",
      twitter: "iabhishekbasucs",
    },
    _id: "643982ebb6c4febf1c5f33d1",
    first_name: "abhishek",
    last_name: "basu",
    email: "iabhishekbasu@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/abhishekbasu.webp",
    // office_post: "President",
  },

  {
    socials: {
      instagram: "archisman78",
      linkedin: "in/archisman-ghosh-8724511a2/",
      twitter: "archismangh007",
    },
    _id: "642c7869e4928d57dfc22b34",
    first_name: "archisman",
    last_name: "ghosh",
    email: "archiribhu@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/archismanghosh.webp",
    // office_post: "Operations Head",
  },

  {
    socials: {
      instagram: "collection_of_top_cells",
      linkedin: "in/ayush-kumar-gupta-2a9445197/",
      twitter: "",
    },
    _id: "642d72dc77140e24741ffa42",
    first_name: "ayush kumar",
    last_name: "gupta",
    email: "ayushkumargupta1212@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/ayushkumargupta.webp",
    // office_post: "Convenor",
  },

  {
    socials: {
      instagram: "kgulshan827",
      linkedin: "in/gulshan-kumar-choudhary-674910120",
      twitter: "",
    },
    _id: "643982ebb6c4febf1c5f33d5",
    first_name: "gulshan",
    last_name: "choudhary",
    email: "kgulshan827@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/gulshanchoudhary.webp",
    // office_post: "Treasurer",
  },


  

  {
    socials: {
      instagram: "harshit__surana",
      linkedin: "in/harshitsurana",
      twitter: "harshitsurana5",
    },
    _id: "642c7869e4928d57dfc22b32",
    first_name: "harshit",
    last_name: "surana",
    email: "harsh007js@gmail.com",
    category: "alumni_2023",
    post: "642c7d22e4928d57dfc22b3d",
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/harshitsurana.webp",
    // office_post: "Add. General Secretary",
  },


  {
    socials: {
      instagram: "ishhuu2000",
      linkedin: "in/ishan-dutta-9a65221a9",
      twitter: "sindhu__007",
    },
    _id: "643992a4b6c4febf1c5f3478",
    first_name: "ishan ",
    last_name: "dutta",
    email: "ishandutta1220@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/ishandutta.webp",
    // office_post: "General Secretary",
  },

  {
    socials: {
      instagram: "kshitij.shrivastava_",
      linkedin: "in/kshitij-shrivastava-a5aaa4118",
      twitter: "",
    },
    _id: "643982ebb6c4febf1c5f33cd",
    first_name: "kshitij",
    last_name: "shrivastava",
    email: "kshitijshrivastava36@gmail.com",
    category: "alumni_2023",
    post: null,
    batch: "2023",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/kshitijshrivastava.webp",
    // office_post:"Vice President",
  },

  ////////////////////////////////////--------->2025 batch<-----------------//////////////////////////////////////
 

  {
    socials: {
      instagram: "abhi.arya.81",
      linkedin: "in/abhishek-arya-6a8a24223",
      twitter: "",
    },
    _id: "643982ebb6c4febf1c5f33d3",
    first_name: "abhishek",
    last_name: "arya",
    email: "contactabhi0@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/abhishekarya.webp",
    office_post:"Ops. Head",
  },

  {
    socials: {
      instagram: "abhijitsinha01",
      linkedin: "in/abhijit-kumar-sinha-492172235/",
      twitter: "",
    },
    _id: "643982ebb6c4febf1c5f33cf",
    first_name: "abhijit kumar",
    last_name: "sinha",
    email: "kumarsinhaabhijit@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/abhijitkumarsinha.webp",
    office_post:"Alumini Head",
  },

  {
    socials: {
      instagram: "ananda_0_2",
      linkedin: "in/ananda-sau-391830233/",
      twitter: "ananda_sau8",
    },
    _id: "642d72de77140e24741ffa60",
    first_name: "ananda",
    last_name: "sau",
    email: "anandasau707@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/anandasau.webp",
    office_post:"Joint Treasurer",
  },

  {
    socials: {
      instagram: "lord_aniban",
      linkedin: "in/aniruddha-bandyopadhyay-b72a01237/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa6c",
    first_name: "aniruddha",
    last_name: "bandyopadhyay",
    email: "bandyopadhyayaniruddha2@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/aniruddhabandyopadhyay.webp",
    office_post:"Web-Dev Head",
  },

  {
    socials: {
      instagram: "okimher._",
      linkedin: "in/dheeraj-majhi-548b43242",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa64",
    first_name: "dheeraj",
    last_name: "majhi",
    email: "dheerajmajhi07@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/dheerajmajhi.webp",
    office_post:"Publicity Head",
  },

  {
    socials: {
      instagram: "himan.sah.7",
      linkedin: "in/himanshu-sah-3888001b3/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa70",
    first_name: "himanshu",
    last_name: "sah",
    email: "himanshusah90@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/himanshusah.webp",
    office_post:"Joint Secretary",
  },

  {
    socials: {
      instagram: "likhi9_",
      linkedin: "in/sai-likhitha-reddy-a3659a231/",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa5c",
    first_name: "likhitha",
    last_name: "reddy",
    email: "likhitha0341@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/likhithareddy.webp",
    office_post:"Convenor",
  },

  {
    socials: {
      instagram: "madhulina_guha",
      linkedin: "in/madhulinaguha2003/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa78",
    first_name: "madhulina",
    last_name: "guha",
    email: "rguha42@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/madhulinaguha.webp",
    office_post:"Cultural Head",
  },

  {
    socials: {
      instagram: "mridul.7.8",
      linkedin: "company/css-nitdgp/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa6a",
    first_name: "mridul",
    last_name: "sikder",
    email: "mridulsikder15@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2024/04/17/IMG_20240417_034329.jpeg",
    office_post:"Treasurer",
  },


  // {
  //   socials: {
  //     instagram: "neil_madhab_marndi",
  //     linkedin: "in/nilamadhab-marndi-505619228/",
  //     twitter: "neil_madhab",
  //   },
  //   _id: "642d72dd77140e24741ffa56",
  //   first_name: "nilamadhab",
  //   last_name: "marndi",
  //   email: "neilmadhab007@gmail.com",
  //   category: "final",
  //   post: null,
  //   batch: "2025",
  //   __v: 0,
  //   image: "https://freeimghost.net/images/2023/04/14/nilamadhabmarndi.webp",
  // },
  {
    socials: {
      instagram: "the_priyanshu.jaiswal",
      linkedin: "in/priyanshu-jaiswal-a2b19324b",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa58",
    first_name: "priyanshu",
    last_name: "jaiswal",
    email: "viratbth999@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/11/priyanshu.jpeg",
    office_post:"President",
  },

  {
    socials: {
      instagram: "raja._.paul",
      linkedin: "in/raja-paul-57461b229",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa74",
    first_name: "raja",
    last_name: "paul",
    email: "mrpaulraja1@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/rajapaul.webp",
    office_post:"Sponsorship Head",
  },

  // {
  //   socials: {
  //     instagram: "sattwikmaiti",
  //     linkedin: "in/sattwik-maiti-a582a81a0/",
  //     twitter: "",
  //   },
  //   _id: "642d72de77140e24741ffa5e",
  //   first_name: "sattwik",
  //   last_name: "maiti",
  //   email: "maitisattwik@gmail.com",
  //   category: "final",
  //   post: null,
  //   batch: "2025",
  //   __v: 0,
  //   image: "https://freeimghost.net/images/2023/04/14/sattwikmaiti.webp",
  // },

  {
    socials: {
      instagram: "tomorrow_is_potato",
      linkedin: "in/shubhodaya-sarkar-a582581b8/",
      twitter: "shubhodayasark1",
    },
    _id: "642d72de77140e24741ffa6e",
    first_name: "shubhodaya",
    last_name: "sarkar",
    email: "shubhodayasarkar@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/shubhodayasarkar.webp",
    office_post:"Event Head",
  },

  
  {
    socials: {
      instagram: "sumitnag50",
      linkedin: "in/sumit-kumar-nag-75a3a5238/",
      twitter: "",
    },
    _id: "642d72dd77140e24741ffa5a",
    first_name: "sumit kumar",
    last_name: "nag",
    email: "sumitkumarnag5@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/sumitkumarnag.webp",
    office_post:"Content Head",
  },



  {
    socials: {
      instagram: "jlr_1911",
      linkedin: "in/surajit-shil-9aa1b6224/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa62",
    first_name: "surajit",
    last_name: "shil",
    email: "shilsurajit12@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/surajitshil.webp",
    office_post:"Vice President",
  },

  {
    socials: {
      instagram: "css_nitdgp",
      linkedin: "in/subharthi-bhunia-877490236/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa66",
    first_name: "subharthi",
    last_name: "bhunia",
    email: "subharthib03@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/subharthibhunia.webp",
    office_post:"App-Dev Head",
  },
  {
    socials: {
      instagram: "sanket__mondal18",
      linkedin: "in/sanket-mondal-442177241/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa68",
    first_name: "sanket",
    last_name: "mondal",
    email: "sanketmondal893@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/sanketmondal.webp",
    office_post:"Production Head",
  },
 
 

  {
    socials: {
      instagram: "sooraj_raj__21",
      linkedin: "in/suraj-raj-b20628224/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa72",
    first_name: "suraj",
    last_name: "raj",
    email: "surajraj2109@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/surajraj.webp",
    office_post:"General Secretary",
  },

  {
    socials: {
      instagram: "zone_of_s",
      linkedin: "in/subham-shaw-540abb223/",
      twitter: "",
    },
    _id: "642d72de77140e24741ffa76",
    first_name: "subham",
    last_name: "shaw",
    email: "subhamshaw.india@gmail.com",
    category: "final",
    post: null,
    batch: "2025",
    __v: 0,
    image: "https://freeimghost.net/images/2023/04/14/subhamshaw.webp",
    office_post:"Design Head",
  },






  ///////////////// 2026 batch /////////////////////////////////////////////////////

  {
    socials: {
      instagram: "anweshaa_roy",
      linkedin: "in/anwesha-roy-oishi-31548b266",
      twitter: "mysticZing_AR",
    },
    _id: "kel03art4y74vsj838jp31cn",
    first_name: "Anwesha Roy",
    last_name: "oishi",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/anwesharoyoishife396df6a327d049.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "Arjyad",
      linkedin: "in/arjya-dutta-356449275",
      twitter: "",
    },
    _id: "notv73as7t3bihd1err2l4ty",
    first_name: "Arjya",
    last_name: "Dutta",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/arjyaduttae1548dbdca0dd8eb.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "arunima.biswas_",
      linkedin: "in/arunima-biswas-311873280",
      twitter: "",
    },
    _id: "kgghph8u5q3wz1v7p1jly2ky",
    first_name: "Arunima",
    last_name: "Biswas",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/arunimabiswas.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "sathvika_04.11",
      linkedin: "in/chama-sathvika-4747292b0",
      twitter: "",
    },
    _id: "mcqeqss8p59foftc7k2z89lo",
    first_name: "Chama",
    last_name: "Sathvika",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/c.sathvika.jpeg",
    office_post: ""
  },


  {
    socials: {
      instagram: "sai_sahil_0_1_0_2",
      linkedin: "in/duvvu-sai-sahil-149089262",
      twitter: "",
    },
    _id: "s55hw3d8jqpsjcawpsgazf6u",
    first_name: "DUVVU",
    last_name: "Sai Sahil",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/dsaidahil.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "",
      linkedin: "",
      twitter: "",
    },
    _id: "w0qqp4xuwhgttqxvxkv8reyn",
    first_name: "Sarvepalli",
    last_name: "David Shalom",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/davidshalom.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "_stargazers_shine_31",
      linkedin: "in/debidyuti-kar-522674264",
      twitter: "",
    },
    _id: "adxct8ip02u0gk7ec17sqtp8",
    first_name: "Debidyuti",
    last_name: "Kar",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/debidyutikar.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "ch_h_s_k",
      linkedin: "in/hemanth-sai-krishna-chinta-747570274",
      twitter: "",
    },
    _id: "x22szd5eqkq5ji2bpbmloafa",
    first_name: "Hemanth",
    last_name: "sai krishna",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/hemanthsaikrishna.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "",
      linkedin: "kanikicharla-bala-ganesh-889b69275",
      twitter: "",
    },
    _id: "8nhh7dm30geqdl0f5dy4u1z6",
    first_name: "Kanikicharla",
    last_name: "Bala ganesh",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/kbalaganesh.png",
    office_post: ""
  },

  {
    socials: {
      instagram: "hyt_04_",
      linkedin: "in/laxmi-mondal-94714128b",
      twitter: "",
    },
    _id: "05waabajm2w0dnnedu4mgstr",
    first_name: "Laxmi",
    last_name: "Mondal",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/laxmimondal.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "fa_him7016",
      linkedin: "in/md-fahim-uddin-choudhary-25ab44271",
      twitter: "Fahim21833961",
    },
    _id: "k11gk03b858x8xtnayha6a8x",
    first_name: "Md. Fahim",
    last_name: "Uddin Choudhary",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/fahim.jpeg",
    office_post: ""
  },


  {
    socials: {
      instagram: "",
      linkedin: "in/souvik-dey-83796a21b",
      twitter: "",
    },
    _id: "059ctw5ugx5lfm4xbvzogr5l",
    first_name: "Souvik",
    last_name: "Dey",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/souvikdey.webp",
    office_post: ""
  },

  {
    socials: {
      instagram: "",
      linkedin: "in/shubhajit-shaw-01769525b",
      twitter: "",
    },
    _id: "90z6339ohngv4ofodepn7z3g",
    first_name: "Shubhajit",
    last_name: "Shaw",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/shubhajitshaw.jpeg",
    office_post: ""
  },


  {
    socials: {
      instagram: "subhajeet575",
      linkedin: "in/subhajeet-baskey-361202250",
      twitter: "Subhaje25056446",
    },
    _id: "r2yfqse9flp3hoxg0s9iuxkn",
    first_name: "Subhajeet",
    last_name: "Baskey",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/subhajeetbaskey.jpeg",
    office_post: ""
  },

  {
    socials: {
      instagram: "yuvi10_",
      linkedin: "",
      twitter: "",
    },
    _id: "xwbnqvwric275ynw19v9xzhr",
    first_name: "Yuvraj",
    last_name: "Prakash Sanap",
    email: "",
    category: "third",
    post: null,
    batch: "2026",
    __v: 0,
    image: "https://freeimghost.net/images/2024/02/06/yuvrajsanap.jpeg",
    office_post: ""
  },
/////////////////////////////////////////////


];

export default DATA;
