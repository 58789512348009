import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamCard from "../TeamCard/teamCard";
const cards = [
  {
    id: 1,
    src: "https://freeimghost.net/images/2024/02/11/priyanshu.jpeg",
    name: "Priyanshu Jaiswal",
    position: "President",
    instagram: "the_priyanshu.jaiswal",
    linkedin: "in/priyanshu-jaiswal-a2b19324b",
    twitter: "",
  },
  {
    id: 2,
    src: "https://freeimghost.net/images/2023/04/14/surajitshil.webp",
    name: "Surajit Shil",
    position: "Vice-President",
    instagram: "jlr_1911",
    linkedin: "in/surajit-shil-9aa1b6224/",
    twitter: "",
  },
  {
    id: 3,
    src: "https://freeimghost.net/images/2023/04/14/surajraj.webp",
    name: "Suraj Raj",
    position: "General Secretary",
    instagram: "sooraj_raj__21",
    linkedin: "in/suraj-raj-b20628224/",
    twitter: "",
  },
  {
    id: 4,
    src: "https://freeimghost.net/images/2023/04/14/mridulsikder.webp",
    name: "Mridul Sikder",
    position: "Treasurer",
    instagram: "mridul.7.8",
    linkedin: "company/css-nitdgp/",
    twitter: "",
  },
  {
    id: 5,
    src: "https://freeimghost.net/images/2023/04/14/likhithareddy.webp",
    name: "Likhitha Reddy",
    position: "Convenor",
    instagram: "likhi9_",
    linkedin: "in/sai-likhitha-reddy-a3659a231/",
    twitter: "",
  },
  {
    id: 6,
    src: "https://freeimghost.net/images/2023/04/14/himanshusah.webp",
    name: "Himanshu Sah",
    position: "Joint Secretary",
    instagram: "himan.sah.7",
      linkedin: "in/himanshu-sah-3888001b3/",
      twitter: "",

  },
  {
    id: 7,
    src: "https://freeimghost.net/images/2023/04/14/shubhodayasarkar.webp",
    name: "Shubhodaya Sarkar",
    position: "Event Head",
    instagram: "tomorrow_is_potato",
      linkedin: "in/shubhodaya-sarkar-a582581b8/",
      twitter: "shubhodayasark1",
  },
  {
    id: 8,
    src: "https://freeimghost.net/images/2023/04/14/aniruddhabandyopadhyay.webp",
    name: "Aniruddha Bandyopadhyay",
    position: "Web-Dev Head",
    instagram: "lord_aniban",
    linkedin: "in/aniruddha-bandyopadhyay-b72a01237/",
    twitter: "",
  },
  {
    id: 9,
    src: "https://freeimghost.net/images/2023/04/14/subharthibhunia.webp",
    name: "Subharthi Bhunia",
    position: "App-Dev Head",
    instagram: "the_mundhra",
    linkedin: "in/shubham-mundhra-62b32b242/",
    twitter: "",
  },
  {
    id: 10,
    // src: "https://freeimghost.net/images/2023/04/14/abhishekarya.webp",
    src: "https://iili.io/dV4cMuI.jpg",
    name: "Abhishek Arya",
    position: "Operations & Logistics Head",
    instagram: "abhi.arya.81",
      linkedin: "in/abhishek-arya-6a8a24223",
      twitter: "",
  },
  {
    id: 11,
    src: "https://freeimghost.net/images/2023/04/14/dheerajmajhi.webp",
    name: "Dheeraj Majhi",
    position: "Publicity Head",
    instagram: "okimher._",
      linkedin: "in/dheeraj-majhi-548b43242",
      twitter: "",
  },
  {
    id: 12,
    src: "https://freeimghost.net/images/2023/04/14/madhulinaguha.webp",
    name: "Madhulina Guha",
    position: "Cultural Head",
    instagram: "madhulina_guha",
      linkedin: "in/madhulinaguha2003/",
      twitter: "",
  },
  {
    id: 13,
    src: "https://freeimghost.net/images/2023/04/14/anandasau.webp",
    name: "Ananda Sau",
    position: "Joint Treasurer",
    instagram: "ananda_0_2",
      linkedin: "in/ananda-sau-391830233/",
      twitter: "ananda_sau8",
  },
  {
    id: 14,
    src: "https://freeimghost.net/images/2023/04/14/abhijitkumarsinha.webp",
    name: "Abhijit Kumar Sinha",
    position: "Alumini OutReach Head",
    instagram: "abhijitsinha01",
      linkedin: "in/abhijit-kumar-sinha-492172235/",
      twitter: "",
  },
  {
    id: 15,
    src: "https://freeimghost.net/images/2023/04/14/rajapaul.webp",
    name: "Raja Paul",
    position: "Sponsorship Head",
    instagram: "raja._.paul",
    linkedin: "in/raja-paul-57461b229",
    twitter: "",
  },
  {
    id: 16,
    // src: "https://freeimghost.net/images/2023/04/14/sumitkumarnag.webp",
    src: "https://iili.io/dV48WDF.jpg",
    name: "Sumit Kumar Nag",
    position: "Content Head",
    instagram: "sumitnag50",
      linkedin: "in/sumit-kumar-nag-75a3a5238/",
      twitter: "",
  },
  {
    id: 17,
    // src: "https://freeimghost.net/images/2023/04/14/sanketmondal.webp",
    src: "https://iili.io/dV4roX9.jpg",
    name: "Sanket Mondal",
    position: "Production Head",
    instagram: "sanket__mondal18",
      linkedin: "in/sanket-mondal-442177241/",
      twitter: "",
  },
  {
    id: 18,
    src: "https://freeimghost.net/images/2023/04/14/subhamshaw.webp",
    name: "Subham Shaw",
    position: "Design Head",
    instagram: "zone_of_s",
    linkedin: "in/subham-shaw-540abb223/",
    twitter: "",
  },
];

var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 4,
  initialSlide: 0,
  slidesToScroll: 1,
  autoplay: true,
  arrow: false,
  infinite: true,
  swipeToSlide: true,
  pauseOnHover: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Carousels = () => {
  return (
    <div>
      <Slider {...settings}>
        {cards.map((item, index) => (
          <TeamCard
            src={item.src}
            name={item.name}
            position={item.position}
            key={index}
            linkedin={item.linkedin}
            instagram={item.instagram}
            twitter={item.twitter}

          />
        ))}
      </Slider>
    </div>
  );
};

export default Carousels;
